<template>
    <div>
      <q-btn-dropdown
        color="primary"
        flat
        no-caps
      >
        <template v-slot:label>
          <div class="row items-center no-wrap">
            <div class="text-center" v-text="orderByText" />
          </div>
        </template>
  
        <q-list>
          <q-item clickable v-close-popup @click="handleOrderBy('price', 'asc')">
            <q-item-section>
              <q-item-label>Menor preço</q-item-label>
            </q-item-section>
          </q-item>
  
          <q-item clickable v-close-popup @click="handleOrderBy('price', 'desc')">
            <q-item-section>
              <q-item-label>Maior preço</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="handleOrderBy('discount', 'desc')">
            <q-item-section>
              <q-item-label>Maior desconto</q-item-label>
            </q-item-section>
          </q-item>
  
        </q-list>
      </q-btn-dropdown>
    </div>
  </template>
<script setup>
import { useActions, useGetters } from 'vuex-composition-helpers';
import { computed } from 'vue';

const { 
  setOrderBy, fetchProductsAndFilters, setLoading, setSelectedAllProducts 
} = useActions({
    setOrderBy: 'my_lists/setOrderBy',
    fetchProductsAndFilters: 'my_lists/fetchProductsAndFilters',
    setLoading: 'my_lists/setLoading',
    setSelectedAllProducts: 'my_lists/setSelectedAllProducts',
})

const { getOrderBy } = useGetters({
    getOrderBy: 'my_lists/getOrderBy',
})

const orderByText = computed(() => {
    const text = getOrderBy.value;

    return Object.entries(text).length == 0 ? 'Ordenar' : 
    ( text?.field == 'discount' ? 'Maior desconto' : ( text?.asc_desc == 'desc' ? 'Maior preço' : 'Menor Preço' ) )
})

const handleOrderBy = async ( field, type ) => {
    setLoading(true)

    setSelectedAllProducts([])
    
    setOrderBy({ field: field, asc_desc: type });

    try {
        await fetchProductsAndFilters()
    } catch (error) {
        console.error('Erro ao busca produto', error)
    } finally {
        setLoading(false)
    }
}
</script>
<style scoped>
    .q-btn {
        border: 1px solid rgba(0, 0, 0, 0.24);
        border-radius: 100px;
        min-height: fit-content;
        font-weight: normal;
    }

    :deep(.text-primary) {
      color: #000 !important;
    }

    :deep(.q-item:hover)
    {
      color: var(--primary-custom-color) !important;
    }

    :deep(.q-item .q-item__label) {
      font-weight: normal
    }

    :deep(.q-btn__content) {
      font-size: clamp(12.5px, 2.5vw, 14px);
    }
</style>